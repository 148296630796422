import axios, { AxiosError, AxiosHeaders } from 'axios';

import { identity, logout } from 'src/hocs/AuthProvider';

const createAxiosInstance = (url: string) => {
  const api = axios.create({
    baseURL: url,
  });

  api.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const { response } = error;
      if (response && response.status === 401) {
        try {
          await logout(true);
        } catch (e) {}
      }
      if (response && response.status === 403 && !response.data) {
        response.data = "You don't have permission to access.";
      }
      return Promise.reject(error);
    },
  );

  api.interceptors.request.use(async (config) => {
    const { accessToken } = identity.getCookieAuthState() || {};
    try {
      if (!config.headers) config.headers = {} as AxiosHeaders;
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (e) {}
    return config;
  });

  return api;
};

export default createAxiosInstance;
