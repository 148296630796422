import axios from 'axios';
import { createSlice, createAsyncThunk, miniSerializeError, SerializedError } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { getSchemeListByCompanyId } from 'src/api/earchiveLite/scheme';
import { SchemeResponse, ResultFailType } from 'src/types';

import { isDataAccessError } from '@itm/shared-frontend/lib/components/dataAccess';

type SchemeState = {
  isLoading: boolean;
  hasDataAccess: boolean;
  schemeList: SchemeResponse[];
};

const serializeError = (e: unknown): SerializedError => {
  const name =
    e instanceof axios.AxiosError && isDataAccessError(e.response)
      ? ResultFailType.DataAccessValidation
      : ResultFailType.Default;
  return miniSerializeError({ name });
};

export const fetchSchemeListByCompanyAction = createAsyncThunk(
  'scheme/fetchSchemeListByCompany',
  async (companyId: string) => {
    const res = await getSchemeListByCompanyId(companyId);
    return res.data;
  },
  { serializeError },
);

const initialState: SchemeState = {
  isLoading: false,
  hasDataAccess: true,
  schemeList: [],
};

const schemeSlice = createSlice({
  name: 'scheme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // SuperAdmin
    builder.addCase(fetchSchemeListByCompanyAction.pending, (state: SchemeState) => {
      state.isLoading = true;
      state.hasDataAccess = true;
      state.schemeList = [];
    });
    builder.addCase(fetchSchemeListByCompanyAction.fulfilled, (state: SchemeState, action) => {
      state.isLoading = false;
      state.hasDataAccess = true;
      state.schemeList = action.payload;
    });
    builder.addCase(fetchSchemeListByCompanyAction.rejected, (state: SchemeState, action) => {
      state.hasDataAccess = action.error.name !== ResultFailType.DataAccessValidation;
      state.isLoading = false;
    });

    // Cleanup
    builder.addCase(resetStore, (state: SchemeState) => {
      Object.assign(state, initialState);
    });
  },
});

export default schemeSlice.reducer;
