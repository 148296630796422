export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Configuration
  configurationRoot: '/configuration',
  configurationList: '/configuration/list',
  configurationCreateRoot: '/configuration/create',
  configurationCreateDetails: '/configuration/create/details',
  configurationCreateIndexes: '/configuration/create/:configurationId/indexes',
  configurationCreateIndexesDetails: '/configuration/create/:configurationId/indexes/:indexId/details',
  configurationCreateSearchFieldDetails: '/configuration/create/:configurationId/search-fields/:searchFieldId/details',
  configurationCreateSearchField: '/configuration/create/:configurationId/search-fields',

  configurationViewRoot: '/configuration/view/:configurationId',
  configurationViewDetails: '/configuration/view/:configurationId/details',
  configurationViewIndexesDefinition: '/configuration/view/:configurationId/indexes/definition',
  configurationViewSearchFieldDefinition: '/configuration/view/:configurationId/search-fields/definition',

  configurationUpdateRoot: '/configuration/update/:configurationId',
  configurationUpdateDetails: '/configuration/update/:configurationId/details',

  configurationUpdateIndexesRoot: '/configuration/update/:configurationId/indexes',
  configurationUpdateIndexesDefinition: '/configuration/update/:configurationId/indexes/definition',
  configurationUpdateIndexesCreate: '/configuration/update/:configurationId/indexes/create',
  configurationUpdateIndexesDetails: '/configuration/update/:configurationId/indexes/:indexId/details',

  configurationUpdateSearchFieldRoot: '/configuration/update/:configurationId/search-fields',
  configurationUpdateSearchFieldDefinition: '/configuration/update/:configurationId/search-fields/definition',
  configurationUpdateSearchFieldDetails: '/configuration/update/:configurationId/search-fields/:searchFieldId/details',

  configurationAuditRoot: '/configuration/audit',
  configurationAuditList: '/configuration/audit/list',
  configurationAuditDetails: '/configuration/audit/:configurationAuditId/details',

  // Data management
  dataManagementRoot: '/data-management',
  dataManagementDataUploadList: '/data-management/data-upload/list',
  dataManagementDataUploadViewRoot: '/data-management/data-upload/view/:dataUploadId',

  dataManagementDataSearchList: '/data-management/data-search/list',

  dataManagementDataSearchRecordRoot: '/data-management/data-search/:indexId/record/:recordId',
  dataManagementDataSearchRecordDetails: '/data-management/data-search/:indexId/record/:recordId/details',
  dataManagementDataSearchRecordInnerRecordDetails:
    '/data-management/data-search/:indexId/record/:recordId/inner/:innerRecordId/details',

  dataManagementUserAuditList: '/data-management/user-audit/list',

  // Reporting
  reportingRoot: '/reporting',

  reportingReportsRoot: '/reporting/reports',
  reportingReportsList: '/reporting/reports/list',
  reportingReportsCreateRoot: '/reporting/reports/create',
  reportingReportsCreateDetails: '/reporting/reports/create/details',
  reportingReportsCreateFields: '/reporting/reports/create/:reportId/fields',
  reportingReportsEditRoot: '/reporting/reports/edit/:reportId',
  reportingReportsEditDetails: '/reporting/reports/edit/:reportId/details',
  reportingReportsEditFields: '/reporting/reports/edit/:reportId/fields',

  reportingRunHistoryRoot: '/reporting/history',
  reportingRunHistoryList: '/reporting/history/list',
  reportingRunHistoryCreate: '/reporting/history/create',
  reportingRunHistoryDetails: '/reporting/history/:reportRunId/details',

  // Product Information
  productInfo: '/product-info',
};

export { GuardedRoute } from './GuardedRoute';
