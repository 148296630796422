import { GlobalRole } from '@itm/shared-frontend/lib/types';

export { ResultFailType, SearchOrder } from '@itm/shared-frontend';
export type {
  GlobalRole,
  SelectOption,
  ServerError,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'eArchiveLite_Analyst',
  ClientAdmin = 'eArchiveLite_ClientAdmin',
  Manager = 'eArchiveLite_Manager',
}

export type UserPermissionResponse = {
  userId: string;
  companyId: string;
  companyName: string;
  schemeId: string;
  schemeName: string;
  productId: string;
  productName: string;
  productRoleId: string;
  productRoleName: GlobalRole;
  userProductRoleId: string;
  assignmentDate: string;
};

export type SchemeResponse = {
  id: string;
  name: string;
  companyId: string;
};

export enum ConfigurationStatus {
  Active = 'Active',
  SetupIncomplete = 'SetupIncomplete',
}

export type ConfigurationSearchResponse = {
  id: string;
  name: string;
  status: ConfigurationStatus;
  createdDate: string;
  updatedDate: string;
};

export type ConfigurationSearchResponseWithCount = {
  totalCount: number;
  items: ConfigurationSearchResponse[];
};

export type UserAuditResponse = {
  id: string;
  params: string | null;
  userId: string;
  userName: string | null;
  elapsed: string;
  type: UserAuditType;
  status: UserAuditStatus;
  dateTime: string;
};

export type UserAuditRecordsResponseWithCount = {
  totalCount: number;
  items: UserAuditResponse[];
};

export type DataUploadReponse = {
  id: string;
  fileName: string;
  indexName: string;
  status: DataUploadStatus;
  error: string;
  createdDate: string;
  updatedDate: string;
  schemes: SchemeResponse[];
};

export type DataUploadSearchResponseWithCount = {
  totalCount: number;
  items: DataUploadReponse[];
};

export type CreateConfigurationModel = {
  name: string;
  companyId: string;
  elasticSearchServerId: string;
  schemes: string[];
};

export type ConfigurationSchemeResponse = {
  id: string;
  name: string | null;
};

export type CompanyResponse = {
  id: string;
  name: string;
};

export type ConfigurationDetailsResponse = {
  id: string;
  name: string;
  status: string;
};

export type ElasticSearchServerReponse = {
  id: string;
  name: string;
};

export type CreateIndexModel = {
  configurationId: string;
  name: string;
  sourceFileName: string;
};

export type ConfigurationIndexResponse = {
  id: string;
  name: string;
  sourceFileName: string;
  status: IndexStatus;
  createdDate: string;
  updatedDate: string | null;
};

export enum SearchFieldType {
  Boolean = 'Boolean',
  Byte = 'Byte',
  Integer = 'Integer',
  Long = 'Long',
  Float = 'Float',
  Double = 'Double',
  String = 'String',
  Text = 'Text',
  Timestamp = 'Timestamp',
  Ip = 'Ip',
  Binary = 'Binary',
  Struct = 'Struct',
  Array = 'Array',
  Short = 'Short',
  Half_float = 'Half_float',
  Scaled_float = 'Scaled_float',
  Keyword = 'Keyword',
  Date = 'Date',
  Date_nanos = 'Date_nanos',
  Object = 'Object',
  Nested = 'Nested',
}

export type SearchFieldResponse = {
  id: string;
  name: string | null;
  displayName: string | null;
  type: SearchFieldType;
  useInFilter: boolean;
  useAsAuditId: boolean;
  indexId: string;
  indexName: string | null;
  createdDate: string;
  updatedDate: string | null;
};

export type SearchFieldResponsePagedResponse = {
  totalCount: number;
  items: SearchFieldResponse[] | null;
};

export type GetConfigurationSearchFieldsIndexResponse = {
  id: string;
  name: string | null;
};

export type ConfigurationResponse = {
  id: string;
  name: string;
  elasticServer: ElasticSearchServerReponse;
  schemes: SchemeResponse[];
};

export type UpdateConfigurationModel = {
  id: string;
  name: string;
  companyId: string;
  schemes: string[];
};

export type UpdateIndexModel = {
  id: string;
  name: string;
  sourceFileName: string;
  configurationId: string;
};

export type UpdateSearchFieldModel = {
  companyId: string;
  id: string;
  name: string;
  displayName: string;
  configurationId: string;
  indexId: string;
  type: string;
};

export type IndexDataResponse = {
  id: string;
  indexId: string;
  values: Record<string, string>;
  sections: IndexDataResponseSection[];
  multiSections: IndexDataResponseMultipleSection[];
};

export type DataSearchResponseWithCount = {
  totalCount: number;
  items: IndexDataResponse[];
};

export type IndexDataResponseSection = {
  name: string;
  parentName: string;
  hasExtraDetails: boolean;
  values: Record<string, string>;
};

export type IndexDataResponseMultipleSection = {
  name: string;
  parentName: string;
  values: Record<string, string>[];
};

export type IndexDataSearchFilterResponse = {
  name: string;
  type: FilterType;
};

export type IndexDataSearchFilterModel = {
  name: string;
  value: string;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export enum FilterType {
  Text = 'Text',
  Date = 'Date',
  Number = 'Number',
}

export enum DataUploadStatus {
  PendingUpload = 'PendingUpload',
  Uploaded = 'Uploaded',
  UploadError = 'UploadError',
  Deleting = 'Deleting',
  Deleted = 'Deleted',
  DeletedError = 'DeletedError',
}

export enum IndexStatus {
  Creating = 'Creating',
  Created = 'Created',
  Error = 'Error',
  DataLoaded = 'DataLoaded',
  Deleting = 'Deleting',
}

export enum UserAuditType {
  NotSupported = 'NotSupported',
  Search = 'Search',
  Download = 'Download',
  Delete = 'Delete',
}

export enum UserAuditStatus {
  NotFound = 'NotFound',
  Ok = 'Ok',
  BadRequest = 'BadRequest',
}

export enum ConfigurationArea {
  GeneralDetails = 'GeneralDetails',
  IndexDefinition = 'IndexDefinition',
  SearchFieldDefinition = 'SearchFieldDefinition',
}

export enum ConfigurationAuditAction {
  Other = 'Other',
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted',
}

export type ConfigurationAuditSearchResponse = {
  id: string;
  configurationId: string;
  createdByUserId: string;
  createdByUserName: string | null;
  configurationName: string | null;
  name: string | null;
  area: ConfigurationArea;
  action: ConfigurationAuditAction;
  createDate: string;
};

export type ConfigurationAuditSearchResponsePagedResponse = {
  totalCount: number;
  items: ConfigurationAuditSearchResponse[];
};

export type SearchReportResponse = {
  id: string;
  reportName: string | null;
  configurationName: string | null;
  indexName: string | null;
  createdDate: string;
  updatedDate: string | null;
};

export type SearchReportResponsePagedResponse = {
  maxSize: number;
  totalCount: number;
  items: SearchReportResponse[];
};

export type ConfigurationShortResponse = {
  id: string;
  name: string | null;
};

export type IndexShortResponse = {
  id: string;
  name: string | null;
};

export type CreateReportRequest = {
  name: string;
  configurationId: string;
  indexId: string;
};

export type ReportSearchFieldAvailableResponse = {
  id: string;
  name: string;
  displayName: string;
  updatedDate: string | null;
};

export type ReportSearchFieldResponse = ReportSearchFieldAvailableResponse & {
  type: FilterType;
  useInFilter: boolean;
  order: number;
};

export type ReportSearchFieldAvailableResponsePagedResponse = {
  totalCount: number;
  items: ReportSearchFieldAvailableResponse[] | null;
};

export type ReportSearchFieldOrderDto = {
  id: string;
  order: number;
};

export type ReportResponse = {
  id: string;
  name: string | null;
  dataGroup: string | null;
  configurationName: string | null;
  indexName: string | null;
};

export type ConfigurationAuditChangedFieldDto = {
  name: string | null;
  oldValue: string | null;
  newValue: string | null;
};

export type ConfigurationAuditChangedObjectDto = {
  entityName: string | null;
  action: ConfigurationAuditAction;
  changedFields: ConfigurationAuditChangedFieldDto[] | null;
};

export type ConfigurationAuditDetailsResponse = {
  id: string;
  configurationId: string;
  createdByUserId: string;
  createdByUserName: string | null;
  configurationName: string | null;
  name: string | null;
  area: ConfigurationArea;
  action: ConfigurationAuditAction;
  createDate: string;
  changedObjects: ConfigurationAuditChangedObjectDto[] | null;
};

export type UpdateReportDto = {
  id: string;
  name: string;
  dataGroup?: string;
};

export enum ReportRunStatus {
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
  NoDataFound = 'NoDataFound',
}

export type SearchReportHistoryResponse = {
  id: string;
  reportId: string;
  createdByUserId: string;
  reportName: string | null;
  createdByUserName: string | null;
  reportRunStatus: ReportRunStatus;
  createDate: string;
  fileSize: number;
};

export type SearchReportHistoryResponsePagedResponseWithSize = {
  totalCount: number;
  items: SearchReportHistoryResponse[];
  maxSize: number; // in bytes
};

export type DeleteSearchRecordsModel = {
  recordId: string;
  indexId: string;
};

export type BulkDeleteSearchRecordsModel = {
  companyId: string;
  deleteSearchRecords: DeleteSearchRecordsModel[];
};

export type SimpleReportResponse = {
  id: string;
  name: string | null;
};

export type RunReportDto = {
  id: string;
  filters: Record<string, string | undefined>;
};

export enum ExportRecordType {
  CSV = 'CSV',
  JSON = 'JSON',
}
