import { Converter } from '@itm/shared-frontend/lib/utils';
import getEnvVariable from './getEnvVariable';

import {
  TargetPlatform,
  SelectOption,
  ConfigurationStatus,
  ConfigurationArea,
  DataUploadStatus,
  UserAuditType,
  UserAuditStatus,
  ExportRecordType,
} from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const EARCHIVE_LITE_API_URL = getEnvVariable('EARCHIVE_LITE_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';
export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

export const configurationStatusOptions: SelectOption[] = Converter.enumToSelectOptions(ConfigurationStatus);
export const configurationAreaOptions: SelectOption[] = Converter.enumToSelectOptions(ConfigurationArea);
export const dataUploadStatusOptions: SelectOption[] = Converter.enumToSelectOptions(DataUploadStatus);
export const userAuditTypeOptions: SelectOption[] = Converter.enumToSelectOptions(UserAuditType);
export const userAuditStatusOptions: SelectOption[] = Converter.enumToSelectOptions(UserAuditStatus);
export const exportRecordTypeOptions: SelectOption[] = Converter.enumToSelectOptions(ExportRecordType);
