import { useState, useCallback, PropsWithChildren, useEffect, useRef } from 'react';

import store, { useSelector, useDispatch, resetStore } from 'src/store';
import { setIdentityUser } from 'src/store/slices/authSlice';
import { setTenantBranding } from 'src/store/slices/tenantSlice';
import { fetchCompaniesAllAction } from 'src/store/slices/companySlice';
import { fetchProductInfoAction } from 'src/store/slices/productSlice';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';

import { PageLoading, ActivityDetector } from '@itm/shared-frontend/lib/components';
import { IdentityCommonService, applyTenantBranding } from '@itm/shared-frontend/lib/components/initial';

import { AUTH_API_URL } from 'src/utils/constants';

import { clientPortalApi } from 'src/api';

const REFRESH_TOKEN_CHECK_TIME = 5 * 1000; // 5 sec
const REFRESH_TOKEN_IN_ADVANCE_TIME = 20 * 1000; // 20 sec - must be greater than REFRESH_TOKEN_CHECK_TIME

export const identity = new IdentityCommonService(AUTH_API_URL, () => {
  store.dispatch(resetStore());
});
/**
 * @param isLocal uses to prevent the token invalidation request
 */
export const logout = identity.createLogout();

const initTenantBranding = async () => {
  const tenantBranding = await applyTenantBranding(clientPortalApi);
  if (!tenantBranding) return;
  const { logoUrl = null, name = null, termsAndConditions = null } = tenantBranding;

  store.dispatch(setTenantBranding({ logoUrl, name, termsAndConditions }));
};

function AuthProvider(props: PropsWithChildren<object>) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const [isInitialized, setIsInitialized] = useState(false);
  const accessTokenRef = useRef<string | null>(null);

  const checkTokenUpdated = useCallback(
    async (isInitial?: boolean) => {
      try {
        await identity.checkTokenExpiration(REFRESH_TOKEN_IN_ADVANCE_TIME, logout);
        const { accessToken = null } = identity.getCookieAuthState() || {};

        const prevAccessToken = accessTokenRef.current;

        if (prevAccessToken !== accessToken) {
          const { data: identityUser } = await identity.getCurrentUserRequest();

          dispatch(setIdentityUser(identityUser));

          accessTokenRef.current = accessToken;

          // Make some requests here
          if (isInitial && identityUser) {
            await Promise.allSettled([
              initTenantBranding(),
              dispatch(fetchCompaniesAllAction()),
              dispatch(fetchProductInfoAction()),
            ]);
          }
        }
      } catch {
        dispatch(resetStore());
        console.log('User is not logged in.');
      } finally {
        setIsInitialized(true);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    checkTokenUpdated(true);
    const intervalId = window.setInterval(() => {
      checkTokenUpdated();
    }, REFRESH_TOKEN_CHECK_TIME);
    return () => {
      clearInterval(intervalId);
    };
  }, [checkTokenUpdated]);

  return (
    <>
      {isInitialized ? (
        <>
          {props.children}
          <ActivityDetector isLoggedIn={isLoggedIn} logout={logout} />
        </>
      ) : (
        <div className="container has-text-centered py-6">
          <PageLoading />
        </div>
      )}
    </>
  );
}

export default AuthProvider;
