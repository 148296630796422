import { useEffect, useMemo, useState } from 'react';
import { useLocation, matchPath, PathMatch, generatePath } from 'react-router-dom';
import { RoutePath as rp } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { setBreadcrumbsNames } from 'src/store/slices/breadcrumbsSlice';
import { breadcrumbsNamesMapSelector } from 'src/store/selectors/breadcrumbsSelector';

import { Breadcrumb, Breadcrumbs } from '@itm/shared-frontend/lib/components';

type BreadcrumbConfig = {
  name: string | null | undefined;
  parentPath: string | null;
};

type ExtendedBreadcrumbsConfig = BreadcrumbConfig & Pick<PathMatch<string>, 'pathname'>;

type BreadcrumbConfigMap = {
  [key: string]: BreadcrumbConfig;
};

type UseSetBreadcrumbsNamesProps = Readonly<{
  routePath: string | null;
  value: string | null | undefined;
  isClearOnUnmount?: boolean;
}>;

export const useSetBreadcrumbsNames = ({ routePath, value, isClearOnUnmount = true }: UseSetBreadcrumbsNamesProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value || !routePath) return;
    dispatch(setBreadcrumbsNames({ [routePath]: value }));
  }, [routePath, value, dispatch]);

  useEffect(
    () => () => {
      if (!isClearOnUnmount || !routePath) return;
      dispatch(setBreadcrumbsNames({ [routePath]: null }));
    },
    [routePath, isClearOnUnmount, dispatch],
  );
};

const getBreadcrumbs = (pathToMatch: string, breadcrumbsConfig: BreadcrumbConfigMap): Breadcrumb[] => {
  const matchedList = Object.keys(breadcrumbsConfig).reduce<PathMatch<string>[]>((acc, route) => {
    const matched = matchPath(route, pathToMatch);
    return matched ? [...acc, matched] : acc;
  }, []);

  if (!matchedList.length) return [];

  const pathMatch =
    // try to find the full match
    matchedList.find((m) => !m.pattern.path.endsWith('/*')) ||
    // if not found, find the longest wildcard match
    matchedList.toSorted((a, b) => b.pattern.path.length - a.pattern.path.length)[0];

  const routeParams = pathMatch.params;

  let revertedBreadcrumbsList: Breadcrumb[] = [];
  let extendedConfig: ExtendedBreadcrumbsConfig | null = {
    pathname: pathMatch.pathname,
    ...breadcrumbsConfig[pathMatch.pattern.path],
  };

  while (extendedConfig) {
    const { name, pathname, parentPath }: ExtendedBreadcrumbsConfig = extendedConfig;

    if (name) {
      revertedBreadcrumbsList.push({ name, to: pathname });
    } else {
      revertedBreadcrumbsList = [];
    }

    extendedConfig = parentPath
      ? { ...breadcrumbsConfig[parentPath], pathname: generatePath(parentPath, routeParams) }
      : null;
  }

  return revertedBreadcrumbsList.reverse();
};

function BreadcrumbsTrail() {
  const { pathname } = useLocation();
  const bcNamesMap = useSelector(breadcrumbsNamesMapSelector);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const isHidden = useMemo(
    () =>
      Boolean(
        matchPath(rp.root, pathname) ||
          matchPath(rp.loginRedirect, pathname) ||
          matchPath(rp.permissionDenied, pathname),
      ),
    [pathname],
  );

  const config: BreadcrumbConfigMap = useMemo(
    () => ({
      [rp.root]: { name: 'eArchive Lite', parentPath: null },

      // CONFIGURATION
      [rp.configurationRoot]: { name: 'Configuration', parentPath: rp.root },
      [rp.configurationList]: { name: 'All Configurations', parentPath: rp.configurationRoot },

      // Configuration / Create
      [`${rp.configurationCreateRoot}/*`]: { name: 'Create new configuration', parentPath: rp.configurationRoot },
      [rp.configurationCreateIndexesDetails]: {
        name: bcNamesMap[rp.configurationUpdateIndexesDetails],
        parentPath: `${rp.configurationCreateRoot}/*`,
      },

      // Configuration / View
      [rp.configurationViewRoot]: {
        name: bcNamesMap[rp.configurationViewRoot],
        parentPath: rp.configurationList,
      },
      [rp.configurationViewDetails]: { name: 'General Details', parentPath: rp.configurationViewRoot },
      [rp.configurationViewIndexesDefinition]: { name: 'Index Definition', parentPath: rp.configurationViewRoot },
      [rp.configurationViewSearchFieldDefinition]: {
        name: 'Search Field Definition',
        parentPath: rp.configurationViewRoot,
      },

      // Configuration / Update
      [rp.configurationUpdateRoot]: {
        name: bcNamesMap[rp.configurationUpdateRoot],
        parentPath: rp.configurationList,
      },
      [rp.configurationUpdateDetails]: { name: 'General Details', parentPath: rp.configurationUpdateRoot },
      // Configuration / Update / Index definition
      [rp.configurationUpdateIndexesDefinition]: { name: 'Index Definition', parentPath: rp.configurationUpdateRoot },
      [rp.configurationUpdateIndexesCreate]: {
        name: 'Create index',
        parentPath: rp.configurationUpdateIndexesDefinition,
      },
      [rp.configurationUpdateIndexesDetails]: {
        name: bcNamesMap[rp.configurationUpdateIndexesDetails],
        parentPath: rp.configurationUpdateIndexesDefinition,
      },
      // Configuration / Update / Search field definition
      [rp.configurationUpdateSearchFieldDefinition]: {
        name: 'Search Field Definition',
        parentPath: rp.configurationUpdateRoot,
      },
      [rp.configurationUpdateSearchFieldDetails]: {
        name: bcNamesMap[rp.configurationUpdateSearchFieldDetails],
        parentPath: rp.configurationUpdateSearchFieldDefinition,
      },

      // Configuration / Audit
      [rp.configurationAuditList]: { name: 'Audit', parentPath: rp.configurationRoot },
      [rp.configurationAuditDetails]: { name: 'View Audit', parentPath: rp.configurationAuditList },

      // DATA MANAGEMENT
      [rp.dataManagementRoot]: { name: 'Data Management', parentPath: rp.root },

      // Data Management / Data Upload History
      [rp.dataManagementDataUploadList]: { name: 'Data Upload History', parentPath: rp.dataManagementRoot },
      [rp.dataManagementDataUploadViewRoot]: {
        name: 'Upload Summary',
        parentPath: rp.dataManagementDataUploadList,
      },

      // Data Management / Search
      [rp.dataManagementDataSearchList]: { name: 'Search', parentPath: rp.dataManagementRoot },
      [`${rp.dataManagementDataSearchRecordRoot}/*`]: {
        name: 'Data Record',
        parentPath: rp.dataManagementDataSearchList,
      },

      // Data Management / Audit
      [rp.dataManagementUserAuditList]: { name: 'Audit', parentPath: rp.dataManagementRoot },

      // REPORTING
      [rp.reportingRoot]: { name: 'Reporting', parentPath: rp.root },

      // Reporting / Reports
      [rp.reportingReportsList]: { name: 'Configure Report', parentPath: rp.reportingRoot },
      [`${rp.reportingReportsCreateRoot}/*`]: { name: 'Create New Report', parentPath: rp.reportingReportsList },
      [rp.reportingReportsEditRoot]: {
        name: bcNamesMap[rp.reportingReportsEditRoot],
        parentPath: rp.reportingReportsList,
      },
      [rp.reportingReportsEditDetails]: { name: 'Report Details', parentPath: rp.reportingReportsEditRoot },
      [rp.reportingReportsEditFields]: { name: 'Field Selection', parentPath: rp.reportingReportsEditRoot },

      // Reporting / History
      [rp.reportingRunHistoryRoot]: { name: 'Report History', parentPath: rp.reportingRoot },
      [rp.reportingRunHistoryList]: { name: 'Report History', parentPath: rp.reportingRoot },
      [rp.reportingRunHistoryCreate]: { name: 'Run Report', parentPath: rp.reportingRunHistoryList },

      // PRODUCT INFO
      [rp.productInfo]: { name: 'Product Information', parentPath: rp.root },
    }),
    [bcNamesMap],
  );

  // Debounce breadcrumbs update to avoid unnecessary updates by redirects
  useEffect(() => {
    if (isHidden) return;

    const delayTimeout = setTimeout(() => {
      setBreadcrumbs(getBreadcrumbs(pathname, config));
    }, 50);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, [config, isHidden, pathname]);

  return isHidden ? null : <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

export default BreadcrumbsTrail;
